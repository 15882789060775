import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#33CCFF",
        secondary: "#868686",
        accent: "#B6BDC3"
      }
    }
  }
});
