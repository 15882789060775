export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA6do8APi11xZ83shS342pyt8RfXT2yYCQ",
  authDomain: "neumopush.firebaseapp.com",
  databaseURL: "https://neumopush.firebaseio.com",
  projectId: "neumopush",
  storageBucket: "neumopush.appspot.com",
  messagingSenderId: "66194679801",
  appId: "1:66194679801:web:57cfe9966895c926fa7f28",
  measurementId: "G-ZV5HLS2XTH"
};
