






import { Component, Vue } from "vue-property-decorator";
import ChatEmbedded from "@/components/ChatEmbedded/ChatEmbedded.vue"; // @ is an alias to /src

@Component({
  components: {
    ChatEmbedded
  }
})
export default class Home extends Vue {}
