import Vue from "vue";
import { Component } from "vue-property-decorator";
import ServiceProvider from "./providers/ServiceProvider.vue";
import "firebase/auth";
import { STYLE_CLASSES } from "./shared/constants/StyleConstants";
import SnackbarTemplate from "./shared/components/snackbar/SnackbarTemplate.vue";
import { FORM_CONSTANTS } from "./shared/constants/FormConstants";

@Component({
  name: "App",
  components: {
    ServiceProvider,
    SnackbarTemplate
  }
})
export default class App extends Vue {
  btnClassTertiary = STYLE_CLASSES.TERTIARY_BTN;
  NEW_VERSION = FORM_CONSTANTS.LABEL.NEW_VERSION;
  DOWNLOAD_APP = FORM_CONSTANTS.LABEL.DOWLOAD_APP;
  HERE_BTN = FORM_CONSTANTS.BUTTON.HERE;
  DOWNLOAD_BTN = FORM_CONSTANTS.BUTTON.DOWNLOAD;
  deferredPrompt: any;
  refreshNotify = false;
  downloadNotify = false;

  async mounted() {
    window.addEventListener("beforeinstallprompt", (e: Event) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.downloadNotify = true;
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
      this.downloadNotify = false;
    });

    this.checkUpdate();

    let refreshing: boolean;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }

  async checkUpdate() {
    let newWorker: ServiceWorker | null;
    const reload: HTMLLinkElement = this.$refs.reload as HTMLLinkElement;

    reload.addEventListener("click", () => {
      newWorker?.postMessage({ action: "skipWaiting" });
    });

    const registration = await navigator.serviceWorker.getRegistration();
    registration?.addEventListener("updatefound", () => {
      newWorker = registration.installing;

      newWorker?.addEventListener("statechange", () => {
        switch (newWorker?.state) {
          case "installed":
            if (navigator.serviceWorker.controller) {
              this.refreshNotify = true;
            }
            break;
        }
      });
    });
  }

  async dismiss() {
    this.deferredPrompt = null;
  }

  async install() {
    this.deferredPrompt.prompt();
    this.downloadNotify = false;

    const res = await this.deferredPrompt.userChoice;
    if (res.outcome === "accepted") {
      this.downloadNotify = false;
    }
  }
}
