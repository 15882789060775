const PRINCIPAL_CLASSES = {
  BUTTON: "rounded-pill font-weight-bold elevation-6 ma-2 ",
  ROUND_BUTTON: "v-btn--fab v-btn--round ma-2 ",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "accent"
};

export const STYLE_CLASSES = {
  PRIMARY_BTN: PRINCIPAL_CLASSES.BUTTON + PRINCIPAL_CLASSES.PRIMARY,
  SECONDARY_BTN: PRINCIPAL_CLASSES.BUTTON + PRINCIPAL_CLASSES.SECONDARY,
  TERTIARY_BTN: PRINCIPAL_CLASSES.BUTTON + PRINCIPAL_CLASSES.TERTIARY,
  TEXT_FIELD: "rounded-pill v-input--dense v-text-field--solo",
  CARD: "rounded-xl elevation-10"
};

export const SNACKBAR_STYLE = {
  WARNING: {
    COLOR: "#f7a072",
    ICON: "mdi-alert"
  },
  ERROR: {
    COLOR: "#f39a9d",
    ICON: "mdi-close-circle"
  },
  SUCCESS: {
    COLOR: "#69d1c5",
    ICON: "mdi-check"
  },
  INFO: {
    COLOR: "#9cc4d5",
    ICON: "mdi-information"
  }
};
