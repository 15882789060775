import { Component } from "vue-property-decorator";
import Vuetify from "vuetify";
import Vue from "vue";
import EventBus from "@/eventBus";
import { FORM_CONSTANTS } from "@/shared/constants/FormConstants";
import { SNACKBAR_STYLE } from "@/shared/constants/StyleConstants";

Vue.use(Vuetify);
@Component({
  name: "SnackbarTemplate"
})
export default class SnackbarTemplate extends Vue {
  snackbar = false;
  message = "";
  icon = "";
  snackbarColor = "";

  mounted() {
    EventBus.$on(FORM_CONSTANTS.EVENT, (payload: any) => {
      this.message = payload.msg;
      this.icon = payload.icon;
      this.snackbarColor = payload.color;
      this.snackbar = true;
    });
  }

  static popSuccess(msg: string) {
    EventBus.$emit(FORM_CONSTANTS.EVENT, {
      msg,
      icon: SNACKBAR_STYLE.SUCCESS.ICON,
      color: SNACKBAR_STYLE.SUCCESS.COLOR
    });
  }

  static popWarning(msg: string) {
    EventBus.$emit(FORM_CONSTANTS.EVENT, {
      msg,
      icon: SNACKBAR_STYLE.WARNING.ICON,
      color: SNACKBAR_STYLE.WARNING.COLOR
    });
  }

  static popError(msg: string) {
    EventBus.$emit(FORM_CONSTANTS.EVENT, {
      msg,
      icon: SNACKBAR_STYLE.ERROR.ICON,
      color: SNACKBAR_STYLE.ERROR.COLOR
    });
  }

  static popInfo(msg: string) {
    EventBus.$emit(FORM_CONSTANTS.EVENT, {
      msg,
      icon: SNACKBAR_STYLE.INFO.ICON,
      color: SNACKBAR_STYLE.INFO.COLOR
    });
  }

  close() {
    this.snackbar = false;
  }
}
