export const FORM_CONSTANTS = {
  BUTTON: {
    CANCEL: "Cancelar",
    HERE: "Aquí",
    DOWNLOAD: "Descargar",
    ACCEPT: "Aceptar"
  },
  LABEL: {
    DOWLOAD_APP: "Descargar la aplicación",
    NEW_VERSION: "Hay una nueva versión"
  },
  EVENT: "AlertData",
  MODAL: {
    TITLE: "Cerrar sesión",
    TEXT: "¿Está seguro de cerrar la sesión?"
  },
  GLOBAL_VARS: {
    SERVICE_WORKER: "serviceWorker",
    PUSH_MANAGER: "PushManager"
  }
};

export const FORM_MESSAGES = {
  REQUIRED_FIELD: "Este campo es obligatorio",
  INVALID_FORMAT: "Formato inválido",
  REQUEST_FAILED: "Ha ocurrido un error, intente más tarde",
  FORM_ERRORS: "Por favor corrija los errores e intente nuevamente"
};

const ALPHANUMERIC_PATTERN = /^[a-zA-Z0-9 ]*$/;
const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

export const FORM_VALIDATORS = {
  REQUIRED_FIELD: (v: string | number) => !!v || FORM_MESSAGES.REQUIRED_FIELD,
  ALPHANUMERIC_PATTERN: (v: string) =>
    v.length > 0
      ? ALPHANUMERIC_PATTERN.test(v) || FORM_MESSAGES.INVALID_FORMAT
      : true,
  EMAIL_FIELD: (v: string) =>
    v.length > 0 ? EMAIL_PATTERN.test(v) || FORM_MESSAGES.INVALID_FORMAT : true
};
