import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import { FIREBASE_CONFIG } from "./shared/constants/ConfigConstants";
import "./shared/styles/General.scss";

firebase.initializeApp(FIREBASE_CONFIG);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
