import { Component, Inject, Vue } from "vue-property-decorator";
import { STYLE_CLASSES } from "@/shared/constants/StyleConstants";
import firebase from "firebase/app";
import "firebase/auth";
import SnackbarTemplate from "@/shared/components/snackbar/SnackbarTemplate";
import {
  FORM_CONSTANTS,
  FORM_MESSAGES,
  FORM_VALIDATORS
} from "@/shared/constants/FormConstants";
import { AuthService } from "../services/AuthService";
import { Http } from "@status/codes";
import {
  REGISTER_CONSTANS,
  RESET_PASS_CONSTANTS
} from "../constants/LoginConstans";

@Component({
  name: "ResetPass"
})
export default class ResetPass extends Vue {
  @Inject() authService!: AuthService;

  cardClass = STYLE_CLASSES.CARD;
  textFieldClass = STYLE_CLASSES.TEXT_FIELD;
  secBtnClass = STYLE_CLASSES.SECONDARY_BTN;
  priBtnClass = STYLE_CLASSES.PRIMARY_BTN;

  PAGE_TITLE = RESET_PASS_CONSTANTS.PAGE_TITLE;
  ID_NUMBER = RESET_PASS_CONSTANTS.LABEL.ID_NUMBER;
  NEW_PASS = RESET_PASS_CONSTANTS.LABEL.NEW_PASSWORD;
  CONF_PASS = RESET_PASS_CONSTANTS.LABEL.CONFIRM_PASS;
  SEND_BTN = RESET_PASS_CONSTANTS.BUTTON.SEND;
  RESET_BTN = RESET_PASS_CONSTANTS.BUTTON.RESET_PASS;
  CANCEL_BTN = FORM_CONSTANTS.BUTTON.CANCEL;
  CONFIRM_BTN = RESET_PASS_CONSTANTS.BUTTON.CONFIRM;
  CONFIRM_CODE = RESET_PASS_CONSTANTS.CONFIRM_CODE;
  CODE_SEND = RESET_PASS_CONSTANTS.CODE_SENT;
  SITE_KEY = process.env.VUE_APP_SITE_KEY;

  requiredField = [FORM_VALIDATORS.REQUIRED_FIELD];
  passwordRules = [
    (v: string) => v.length >= 4 || REGISTER_CONSTANS.MESSAGE.MIN_LENGTH
  ];

  exists = false;
  idNumber = "";
  password = "";
  repeatPass = "";
  resetPassForm = false;
  verificationId = "";
  verificationCode = "";
  dialog = false;
  phoneNumber = "";
  captchaValue = "";
  applicationVerifier!: firebase.auth.RecaptchaVerifier;

  mounted() {
    this.applicationVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response: string) => {
          this.captchaValue = response;
        }
      }
    );
    this.applicationVerifier.render();
  }

  async sendCode() {
    try {
      if (this.captchaValue) {
        const response = await this.authService.validateUser(this.idNumber);

        if (response.code === Http.Found && response.data) {
          this.phoneNumber = response.data.substr(response.data.length - 4);

          const provider = new firebase.auth.PhoneAuthProvider();
          this.verificationId = await provider.verifyPhoneNumber(
            "+57" + response.data,
            this.applicationVerifier
          );

          this.dialog = true;
        } else {
          SnackbarTemplate.popWarning(response.message);
        }
      }
    } catch (error) {
      SnackbarTemplate.popError(FORM_MESSAGES.REQUEST_FAILED);
    }
  }

  async validateCode() {
    if (this.verificationCode) {
      this.dialog = false;
      const r = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.verificationCode
      );
      await firebase.auth().signInWithCredential(r);
      this.exists = true;
      firebase.auth().currentUser?.delete();
    }
  }

  async resetPass() {
    if (this.resetPassForm) {
      try {
        const response = await this.authService.resetPassword(
          this.idNumber,
          this.password
        );

        if (response.code === Http.Ok) {
          SnackbarTemplate.popSuccess(response.message);
          this.$router.push("/login");
        } else {
          SnackbarTemplate.popError(response.message);
        }
      } catch (error) {
        SnackbarTemplate.popError(FORM_MESSAGES.REQUEST_FAILED);
      }
    } else {
      SnackbarTemplate.popWarning(FORM_MESSAGES.FORM_ERRORS);
    }
  }

  matchPassword() {
    return (
      this.repeatPass === this.password || REGISTER_CONSTANS.MESSAGE.MATCH_PASS
    );
  }
}
