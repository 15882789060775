import axios from "axios";
import { Response } from "../../../shared/models/Response";
import { Subscription } from "../models/Subscription";

export class NotificationService {
  baseUrl: string;

  constructor() {
    this.baseUrl = `${process.env.VUE_APP_NOTIFICATION_API}/notifications`;
  }

  async saveSubscription(data: Subscription): Promise<Response<string | void>> {
    const serviceURL = `${this.baseUrl}/subscription`;
    const response = await axios.post(serviceURL, data);
    return response.data;
  }

  async deleteSubscription(
    subscription: Subscription
  ): Promise<Response<string | void>> {
    const serviceURL = `${this.baseUrl}/by-subscription-and-user`;
    const response = await axios.delete(serviceURL, {
      data: subscription
    });
    return response.data;
  }

  async deleteNotification(
    notificationId: string
  ): Promise<Response<string | void>> {
    const serviceURL = `${this.baseUrl}/${notificationId}`;
    const response = await axios.delete(serviceURL);
    return response.data;
  }
}
