import { Component, Vue, Inject } from "vue-property-decorator";
import { STYLE_CLASSES } from "@/shared/constants/StyleConstants";
import {
  FORM_CONSTANTS,
  FORM_MESSAGES,
  FORM_VALIDATORS
} from "@/shared/constants/FormConstants";
import { AuthService } from "./services/AuthService";
import { Http } from "@status/codes";
import { LOGIN_CONSTANTS } from "./constants/LoginConstans";
import SnackbarTemplate from "@/shared/components/snackbar/SnackbarTemplate";

@Component({
  name: "Login"
})
export default class Login extends Vue {
  @Inject() authService!: AuthService;

  cardClass = STYLE_CLASSES.CARD;
  textFieldClass = STYLE_CLASSES.TEXT_FIELD;
  priBtnClass = STYLE_CLASSES.PRIMARY_BTN;
  secBtnClass = STYLE_CLASSES.SECONDARY_BTN;

  PAGE_TITLE = LOGIN_CONSTANTS.PAGE_TITLE;
  USER = LOGIN_CONSTANTS.LABEL.USER;
  PASSWORD = LOGIN_CONSTANTS.LABEL.PASSWORD;
  USER_PASSWORD_BTN = LOGIN_CONSTANTS.BUTTON.LOGIN;
  USER_ANONYM_BTN = LOGIN_CONSTANTS.BUTTON.LOGIN_INV;
  REGISTER_BTN = LOGIN_CONSTANTS.BUTTON.REGISTER;
  ENTER_BTN = LOGIN_CONSTANTS.BUTTON.ENTER;
  CANCEL_BTN = FORM_CONSTANTS.BUTTON.CANCEL;
  FORGOT = LOGIN_CONSTANTS.LABEL.FORGOT;

  requiredField = [FORM_VALIDATORS.REQUIRED_FIELD];
  inputUser = "";
  inputPassword = "";
  loginForm = false;
  login = false;

  async onLogin() {
    try {
      const userToken = await this.authService.login(
        this.inputUser,
        this.inputPassword
      );

      if (userToken.code == Http.Found) {
        const user = await this.authService.loginByToken(userToken.message);

        if (user) {
          const token = await user.user?.getIdToken();
          const currentUser = user.user?.uid;

          if (token) {
            this.authService.setToken(token, currentUser);
            localStorage.setItem("TYPE", LOGIN_CONSTANTS.PATIENT_USER);

            const encondeId = await this.authService.encodeId(this.inputUser);

            if (encondeId.code === Http.Ok) {
              const rand = `${encondeId.data.encryptedId}-${encondeId.data.randomNumber}`;
              localStorage.setItem("RAND", rand);
              this.$router.push("/patient");
            }
          }
        }
      } else {
        SnackbarTemplate.popError(LOGIN_CONSTANTS.MESSAGE.INVALID_USER);
      }
    } catch (e) {
      SnackbarTemplate.popError(FORM_MESSAGES.REQUEST_FAILED);
    }
  }

  onValidate() {
    if (this.loginForm) {
      this.onLogin();
    } else {
      SnackbarTemplate.popWarning(LOGIN_CONSTANTS.MESSAGE.INVALID_FORM);
    }
  }

  anonymousSession() {
    this.authService.setToken("NO_TOKEN", undefined);
    localStorage.setItem("TYPE", LOGIN_CONSTANTS.GUEST_USER);
    this.$router.push("/chat/guest");
  }
}
