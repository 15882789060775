






import { AuthService } from "@/components/Login/services/AuthService";
import { NotificationService } from "@/components/Notifications/services/NotificationService";
import { Component, Provide, Vue } from "vue-property-decorator";

@Component({
  name: "ServiceProvider"
})
export default class ServiceProvider extends Vue {
  @Provide()
  authService: AuthService = new AuthService();
  @Provide()
  notificationService: NotificationService = new NotificationService();
}
