import axios, { AxiosResponse } from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import { Response } from "@/shared/models/Response";
import { User } from "../models/User";

export class AuthService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = `${process.env.VUE_APP_AUTH_API}/auth`;
  }

  async login(user: string, pass: string): Promise<Response<void>> {
    const res: AxiosResponse<Response<void>> = await axios.post(
      `${this.baseUrl}/login-by-credentials`,
      {
        user: user,
        password: pass
      }
    );
    return res.data;
  }

  async loginByToken(userToken: string) {
    return await firebase.auth().signInWithCustomToken(userToken);
  }

  async register(user: User): Promise<Response<string | void>> {
    return (await axios.post(`${this.baseUrl}/register`, user)).data;
  }

  async validateUser(id: string): Promise<Response<string | void>> {
    return (await axios.get(`${this.baseUrl}/validate/${id}`)).data;
  }

  async resetPassword(
    id: string,
    pass: string
  ): Promise<Response<string | void>> {
    return (await axios.post(`${this.baseUrl}/reset-password`, { id, pass }))
      .data;
  }

  async decodeToken(token: string): Promise<Response<any | void>> {
    return (await axios.get(`${this.baseUrl}/decode/${token}`)).data;
  }

  async encodeId(id: string): Promise<Response<any | void>> {
    return (await axios.post(`${this.baseUrl}/encrypt`, { id })).data;
  }

  async sendSMS(doc: string, id: number): Promise<any> {
    return (
      await axios.post(
        `https://api.neumobot.com/proxy_wolkvox_consultas/proxy_wolkvox/api/v1/new_sms_registry`,
        { doc, id }
      )
    ).data;
  }

  setToken(token: string, user: string | undefined) {
    localStorage.setItem("TOKEN", token);
    if (user) localStorage.setItem("UID", user);
  }
}
